import ApiHelper from './index';

async function getOPCTags(query = {}, spinner = false) {
  return await ApiHelper.get('opcua/tags/', spinner, query);
}

async function getOPCTag(tagId, spinner = false, query = {}) {
  return await ApiHelper.get(`opcua/tag/${tagId}/`, spinner, query);
}

async function deleteOPCTag(tagId, spinner = false, query = {}) {
  return await ApiHelper.remove(
    `opcua/delete_opc_tag/${tagId}/`,
    spinner,
    query
  );
}

async function updateTag(tagId, payload, spinner = false) {
  return await ApiHelper.patch(`opcua/tag/${tagId}/`, payload, spinner);
}

async function addTag(payload, spinner = false) {
  return await ApiHelper.post(`opcua/add_opc_tag/`, payload, spinner);
}

async function getOPCTagByDevice(
  taskId,
  deviceId,
  spinner = false,
  query = {}
) {
  return await ApiHelper.get(
    `opcua/fetch_all_tags/${taskId}/${deviceId}/`,
    spinner,
    query
  );
}

async function getOPCTagByTaskAndDevice(
  taskId,
  deviceId,
  pageId,
  spinner = false,
  query = {}
) {
  return await ApiHelper.get(
    `opcua/fetch_all_tags/${taskId}/${deviceId}/?page=${pageId}`,
    spinner,
    query
  );
}

async function getOPCTagsByTask(selectedTask, query = {}, spinner = false) {
  return await ApiHelper.get(
    `opcua/tag_search_by_task/${selectedTask}/`,
    spinner,
    query
  );
}

async function getOPCTagsByTaskAndDevice(
  selectedTask,
  deviceId,
  query = {},
  spinner = false
) {
  return await ApiHelper.get(
    `opcua/fetch_all_tags/${selectedTask}/${deviceId}`,
    spinner,
    query
  );
}

async function getAllOPCDevices(spinner = false) {
  return await ApiHelper.get(`opcua/devices/`, spinner, {});
}

async function addOPCDevice(payload, spinner = false) {
  return await ApiHelper.post(`opcua/add_device/`, payload, spinner);
}

async function deleteOPCDevice(deviceId, spinner = false, query = {}) {
  return await ApiHelper.remove(`opcua/${deviceId}/`, spinner, query);
}

async function updateOPCDevice(deviceId, payload, spinner = false) {
  return await ApiHelper.patch(`opcua/${deviceId}/`, payload, spinner);
}

async function getOPCDeviceStatus(
  deviceId,
  action,
  spinner = false,
  query = {}
) {
  return await ApiHelper.get(
    `opcua/device/${deviceId}/${action}`,
    spinner,
    query
  );
}

export default {
  getOPCTags,
  getOPCTag,
  deleteOPCTag,
  addTag,
  updateTag,
  getOPCTagByDevice,
  getOPCTagByTaskAndDevice,
  getOPCTagsByTask,
  getAllOPCDevices,
  addOPCDevice,
  deleteOPCDevice,
  updateOPCDevice,
  getOPCTagsByTaskAndDevice,
  getOPCDeviceStatus
};

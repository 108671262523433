<template>
  <div class="p-4">
    <OPCConnectionManagement />
  </div>
</template>

<script>
import OPCConnectionManagement from './OPCConnectionManagement.vue';
export default {
  components: {
    OPCConnectionManagement
  }
};
</script>
